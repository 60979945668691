

export const mods = `
Biotinylation of K
Biotinylation of N-termini
Hydroxamic-acid(hydroxamic_acid
18O(1) of peptide C-term
18O(2) of peptide C-term
Acetaldehyde +26
Acetylation of K
Acetylation of peptide N-term
Acetylation of protein N-term
Amidation of the peptide C-term
Amidation of the protein C-term
Arginine 13C(6)
Arginine 13C(6) 15N(4)
Carbamidomethylation of C
Carbamidomethylation of E
Carbamidomethylation of K
Carbamilation of K
Carbamilation of protein N-term
Carboxymethylation of C
Citrullination of R
Deamidation of N
Deamidation of N 18O
Deamidation of Q
Dehydration of S
Dehydration of T
Didehydro of T
Diiodination of Y
Dimethylation of K
Dimethylation of K 2H(4)
Dimethylation of K 2H(6)
Dimethylation of K 2H(6) 13C(2)
Dimethylation of R
Dimethylation of peptide N-term
Dimethylation of peptide N-term 2H(4)
Dimethylation of peptide N-term 2H(6)
Dimethylation of peptide N-term 2H(6) 13C(2)
Dioxidation of M
Dioxidation of W
Farnesylation of C
FormylMet of protein N-term
Formylation of K
Formylation of S
Formylation of T
Formylation of peptide N-term
Formylation of protein N-term
Geranyl-geranyl of C
Glutathione of C
Guanidination of K
Guanidination of peptide N-term
Heme B of C
Heme B of H
Hex(1)NAc(1) of S
Hex(1)NAc(1) of T
Hex(5) HexNAc(4) NeuAc(2) Na of N
Hex(5) HexNAc(4) NeuAc(2) of N
HexNAc of S
HexNAc of T
Hexose of K
Homoserine lactone of peptide C-term M
Homoserine of peptide C-term M
ICAT-9
ICAT-O
ICPL0 of K
ICPL0 of peptide N-term
ICPL10 of K
ICPL10 of peptide N-term
ICPL4 of K
ICPL4 of peptide N-term
ICPL6 of K
ICPL6 of peptide N-term
Isoleucine 13C(6) 15N(1)
Label of K 2H(4)(2H
Leucine 13C(6) 15N(1)
Lipoyl of K
Lysine 13C(6)
Lysine 13C(6) 15N(2)
Lysine 2H(4)
Methylation of C
Methylation of D
Methylation of E
Methylation of K
Methylation of R
Methylation of S
Methylthio of C
Methylthio of D
Methylthio of N
NIPCAM of C
Nethylmaleimide of C
Oxidation of C
Oxidation of K
Oxidation of M
Oxidation of P
Palmitoylation of C
Palmitoylation of K
Palmitoylation of S
Palmitoylation of T
Palmitoylation of protein N-term
Phosphorylation of S
Phosphorylation of T
Phosphorylation of Y
Proline 13C(5)
Propionamide of C
Propionamide of K
Propionamide of peptide N-term
Propionyl of K heavy
Propionyl of K light
Propionyl of peptide N-term heavy
Propionyl of peptide N-term light
Pyridylethyl of C
Pyrolidone from E
Pyrolidone from Q
Pyrolidone from carbamidomethylated C
S-nitrosylation
SUMO-2/3 Q87R
Sodium adduct to D
Sodium adduct to E
Sulfation of S
Sulfation of T
Sulfation of Y
TMT 10-plex of K
TMT 10-plex of K+4
TMT 10-plex of K+6
TMT 10-plex of K+8
TMT 10-plex of peptide N-term
TMT 11-plex of K
TMT 11-plex of K+4
TMT 11-plex of K+6
TMT 11-plex of K+8
TMT 11-plex of peptide N-term
TMT 2-plex of K
TMT 2-plex of peptide N-term
TMT 6-plex of K
TMT 6-plex of K+4
TMT 6-plex of K+6
TMT 6-plex of K+8
TMT 6-plex of peptide N-term
Thioacyl of peptide N-term
Trideuterated Methyl Ester of D(methyl
Trideuterated Methyl Ester of E(methyl
Trideuterated Methyl Ester of K(methyl
Trideuterated Methyl Ester of R(methyl
Trideuterated Methyl Ester of peptide C-term(methyl
Trimethylation of K
Trimethylation of R
Trimethylation of protein N-term A
Trioxidation of C
Ubiquitination of K
iTRAQ 4-plex of K
iTRAQ 4-plex of Y
iTRAQ 4-plex of peptide N-term
iTRAQ 8-plex of K
iTRAQ 8-plex of Y
iTRAQ 8-plex of peptide N-term
iodoTMT 6-plex of C
iodoTMT zero of C
mTRAQ of 13C(6) 15N(2)
mTRAQ of K 13C(3) 15N
mTRAQ of K light
mTRAQ of peptide N-term 13C(3) 15N
mTRAQ of peptide N-term 13C(6) 15N(2)
mTRAQ of peptide N-term light
`.split("\n").map(s => s.trim()).filter(x=>x).sort().map(s => ({
    id: s,
    name: s
}))


export const enzymes =
`Trypsin:\tCleaves after RK not followed by P
Arg-C (no P rule):\tCleaves after R
Arg-C:\tCleaves after R not followed by P
Arg-N:\tCleaves before
Asp-N (ambic):\tCleaves before
Asp-N:\tCleaves before
Chymotrypsin (no P rule):\tCleaves after YLFW
Chymotrypsin:\tCleaves after YLFW not followed by P
CNBr:\tCleaves after M
Glu-C:\tCleaves after E
Lys-C (no P rule):\tCleaves after K
Lys-C:\tCleaves after K not followed by P
Lys-N:\tCleaves before
LysargiNase:\tCleaves before
Pepsin A:\tCleaves after LF
Thermolysin:\tCleaves before
Trypsin (no P rule):\tCleaves after RK`
.split("\n").map(s => s.trim()).filter(x=>x).map(s => ({
    id: s,
    name: s
}))


export const fragmentations = [
"ETD", "CID", "HCD"
].map(s => ({
    id: s,
    name: s
}))
