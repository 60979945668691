import {useEffect, useState} from "react";
import _ from "lodash";

const submitJson = (url, data, method) =>
    fetch(url, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })


export const post = (url, data) => submitJson(url, data, 'POST').then(res => res.json())


export const useOpenProtGlobalState = () => {

    const [openProtGlobalState, setOpenProtGlobalState] = useState(null)

    useEffect(() => {

        fetch("/api/1/lab/altorf/releases/all").then(res => res.json()).then(res => {

            const allReleases = res.body.map(r => ({
                ...r,
                allSpecies: r.allSpecies.map(s => ({
                    ...s,
                    id: s.species_id,
                    name: s.specieName
                }))
            }))

            const lastRelease = _
                .chain(allReleases)
                .sortBy(r => r.openProtReleaseId)
                .last()
                .value()

            setOpenProtGlobalState({
                lastRelease,
                allReleases
            })
        })
    },[])

    return {
        openProtGlobalState
    }
}

export const useLastReleaseMetadata = () => {

    const {openProtGlobalState} = useOpenProtGlobalState()

    const [lastReleaseMetadata, setLastReleaseMetadata] = useState(null)

    useEffect(() => {
        if(openProtGlobalState) {
            setLastReleaseMetadata(openProtGlobalState.lastRelease)
        }
    }, [openProtGlobalState && `${openProtGlobalState.lastRelease}`])

    return lastReleaseMetadata
}

export const studyExists = (type, studyId) =>
    fetch(`/api/2/studyExists/${type}/${studyId}`)
    .then(res => res.json())