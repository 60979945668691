import React, {useEffect, useState} from "react";
import {useLastReleaseMetadata} from "../rest";

export const useSearchByGeneAndSpeciesState = () => {

    const lastReleaseMetadata = useLastReleaseMetadata()

    const [selectedSpeciesId, setSelectedSpeciesId] = useState(2)

    const [geneSymbol, setGeneSymbol] = useState("")

    return {
        lastReleaseMetadata,
        selectedSpeciesId,
        setSelectedSpeciesId,
        geneSymbol,
        setGeneSymbol
    }
}

export const SearchByGeneAndSpecies = ({searchByGeneAndSpeciesState}) => {

    const {
        lastReleaseMetadata,
        selectedSpeciesId,
        setSelectedSpeciesId,
        geneSymbol,
        setGeneSymbol
    } = searchByGeneAndSpeciesState

    const geneSymbolValidRegex = /^[a-zA-Z0-9]+$/gmi

    return <>
        <input
            className="input is-rounded"
            type="text" placeholder="Search Gene Name"
            value={geneSymbol}
            onChange={e => {
                if(geneSymbolValidRegex.test(e.target.value)) {
                    setGeneSymbol(e.target.value)
                }
            }}
        />
        <div className="select is-rounded is-fullwidth mt-1">
            <select className="select is-rounded is-fullwidth"
                    value={selectedSpeciesId}
                    onChange={ e => {
                        setSelectedSpeciesId(e.target.value)
                    }}
            >
                {lastReleaseMetadata && lastReleaseMetadata.allSpecies.map(s =>
                    <option
                        value={s.species_id}
                        key={s.species_id}
                    >{s.name}
                    </option>
                )}
            </select>
        </div>
    </>
}
