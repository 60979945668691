import React, {useState, useReducer, useContext, useEffect} from "react";
import {extractInfosFromPDXForMS, samplePXDInJSON} from "../parsers/pxdXMLParser";
import xml2js from "xml2js";
import _ from "lodash"
import {mods as msMods, enzymes, fragmentations} from "../massSpec/peptideShakerArgs"
import {modalInfoContext} from "../App"
import {centeringCol} from "./utils";
import {post, studyExists, useOpenProtGlobalState} from "../rest";
import {MainMenu} from "../uiComponents/MainMenu";


// PXD017531

//`http://proteomecentral.proteomexchange.org/cgi/GetDataset?ID=${pdxId}&outputMode=XML&test=no`
//  http://proteomecentral.proteomexchange.org/cgi/GetDataset?ID=PXD017983&outputMode=XML&test=no
const pxdUrlFetcher = pxdId =>
    fetch(`/cgi/GetDataset?ID=${pxdId}&outputMode=XML&test=no`)
    //fetch(`http://proteomecentral.proteomexchange.org/cgi/GetDataset?ID=${pxdId}&outputMode=XML&test=no`)
    .then((response) => response.text())
    .then((htmlString) => {

        const errIdx = htmlString.indexOf("result=ERROR")
        if(errIdx === -1) {
            return (new xml2js.Parser()).parseStringPromise(htmlString)
            .catch(err => {
                console.log(`error fetching PXD: ${htmlString} ${err}`)
            })
        }

        return {"error": `study ${pxdId} not found.`}
    })


const submitMsStudy = data =>
    post("/api/2/studies/ms", data)


const initialPXDState = () =>  ({
    initialized: false,
    mainForm: {},
    groupIdGenerator: 0,
    sampleHoldersToSelect:[],
    groups:[],
    msInstrumentChoices: []
})

const pxdFormReducer = (state, action) => {


    const firstTransform = () => {

        switch (action.type) {
            case "reset":
                return initialPXDState()
            case "setSavedId": {
                return {
                    ...state,
                    id: action.id,
                    saveSuccessful: true
                }
            }
            case "initFromSampleList":
                return {
                    ...initialPXDState(),
                    initialized: true,
                    studyId: action.studyId,
                    mainForm: {
                        contactEmail: "",
                        pmid: action.extractedPXDInfos.pmid,
                        citation: action.extractedPXDInfos.citation
                    },
                    sampleHoldersToSelect: action.extractedPXDInfos.sampleList.map(s => ({
                        isSelected: false,
                        isExcluded: false,
                        sample: s
                    })),
                    msInstrumentChoices: action.extractedPXDInfos.instruments
                }
            case "initFromSaved": {
                return {
                    ...initialPXDState(),
                    initialized: true,
                    ...action.initialPxdFormState,
                    sampleHoldersToSelect: action.extractedPXDInfos.sampleList.map(s => ({
                        isSelected: false,
                        sample: s
                    }))
                }
            }

            case "updateMainFormField": {

                return {
                    ...state,
                    mainForm: {
                        ...state.mainForm,
                        [action.fieldKey]: action.fieldValue
                    }
                }
            }
            case "assignSelectedStatus": {

                const sampleHoldersToSelect = state.sampleHoldersToSelect.map(h => !action.pred(h) ? h : ({
                    ...h,
                    isSelected: action.isSelected,
                }))

                return {
                    ...state,
                    sampleHoldersToSelect,
                    hasSampleSelected: sampleHoldersToSelect.find(h => h.isSelected)
                }
            }

            case "excludeSelectedSamples": {

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.map(s => ({
                        ...s,
                        isExcluded: s.isSelected || s.isExcluded,
                        isSelected: false
                    }))
                }
            }

            case "unExclude": {

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.map(s => ({
                        ...s,
                        isExcluded: false
                    }))
                }
            }

            case "groupSelectedSamples": {

                const samples = state.sampleHoldersToSelect.filter(h => h.isSelected).map(h => h.sample)

                const msInstrument = state.msInstrumentChoices.length === 1 ? state.msInstrumentChoices[0] : null

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.filter(h => !h.isSelected),
                    groupIdGenerator: state.groupIdGenerator + 1,
                    groups: [
                        {
                            id: state.groupIdGenerator,
                            mods: [
                                //{id: "Phosphorylation of S", name: "Phosphorylation of S"}, {id: "iTRAQ 8-plex of peptide N-term", name: "iTRAQ 8-plex of peptide N-term"}
                            ],
                            userMods: [],
                            enzymes: [],
                            fragmentation: null,
                            msInstrument,
                            species: null,
                            identificationProtocolSelection: {...defaultIdentificationProtocolSelection},
                            samples,
                        },
                        ...state.groups
                    ],
                    hasSampleSelected: false
                }
            }

            case "removeSampleFromGroup": {
                return {
                    ...state,
                    sampleHoldersToSelect: [
                        {
                            isSelected: false,
                            sample: action.sample
                        },
                        ...state.sampleHoldersToSelect
                    ],
                    groups: state.groups.map(g => {

                        if (g.id === action.group.id) {
                            if (g.samples.length === 1) {
                                return null
                            }
                            return {
                                ...g,
                                samples: g.samples.filter(s => s.id !== action.sample.id)
                            }
                        }
                        return g
                    }).filter(x => x)
                }
            }

            case "updateGroup": {

                const updateGroup = group => {
                    switch (action.key) {
                        case "sampleType":
                        case "fragmentation":
                        case "msInstrument":
                        case "species":
                            return {
                                ...group,
                                [action.key]: action.value
                            }
                        case "mods":
                        case "userMods": {
                            return {
                                ...group,
                                [action.key]: action.args.isAdd ?
                                    action.value :
                                    group[action.key].filter(o => o.id !== action.value.id)
                            }
                        }
                        case "enzymes": {
                            return {
                                ...group,
                                [action.key]: action.args.isAdd ?
                                    _.uniqBy([...group[action.key], action.value], o => o.id) :
                                    group[action.key].filter(o => o.id !== action.value.id)
                            }
                        }
                        case "identificationProtocolSelection": {
                            return {
                                ...group,
                                identificationProtocolSelection: {
                                    ...group.identificationProtocolSelection,
                                    [action.args.key]: {
                                        applicable: true,
                                        value: action.value
                                    }
                                }
                            }
                        }
                        case "addSamples": {
                            return {
                                ...group,
                                samples: [
                                    ...state.sampleHoldersToSelect.filter(h => h.isSelected).map(h => h.sample),
                                    ...group.samples
                                ]
                            }
                        }
                        case "updateSample": {
                            return {
                                ...group,
                                samples: group.samples.map(s => {
                                    if(action.args.sample.id !== s.id) {
                                        return s
                                    }
                                    return {
                                        ...s,
                                        [action.args.propKey]: action.value
                                    }
                                })
                            }
                        }
                        default:
                            throw Error(`unknown group prop : ${action.key}`)
                    }
                }

                return {
                    ...state,
                    sampleHoldersToSelect:
                        action.key === "addSamples" ?
                            state.sampleHoldersToSelect.filter(h => !h.isSelected) :
                            state.sampleHoldersToSelect,
                    groups: state.groups.map(g =>
                        g.id === action.group.id ?
                        computeIdentificationProtocolSelection(updateGroup(g)) : g
                    )
                }
            }

            default:
                throw Error(`unknown action type: ${action.type}`)
        }
    }

    const t1 = firstTransform()
    const groups = t1.groups.map(g => ({
        ...g,
        errors: validateGroup(g)
    }))

    const hasGroupInError = !! groups.find(g => g.errors.hasErrors)

    t1.missingContactEmail = ! (!! t1.mainForm.contactEmail)

    const numberOfExcludedSamples = t1.sampleHoldersToSelect.filter(h => h.isExcluded).length
    const allSamplesGroupedOrExcluded = ! (t1.sampleHoldersToSelect.length > numberOfExcludedSamples)

    return {
        ...t1,
        groups,
        hasGroupInError,
        allSamplesGroupedOrExcluded,
        numberOfExcludedSamples,
        canSubmitStudy:
            (! hasGroupInError) &&
            allSamplesGroupedOrExcluded &&
            (!t1.missingContactEmail)
    }
}

const defaultIdentificationProtocolSelection = {
    phospho: {
        applicable: false,
        value: null
    },
    tmt: {
        applicable: false,
        value: null
    },
    iTRAQ: {
        applicable: false,
        value: null
    }
}


const computeIdentificationProtocolSelection = group => {

    const modStartsWith = str =>
        group.mods.find(m => m.name.toUpperCase().startsWith(str))

    const f = ()=> {

        if (modStartsWith("PHOSPHO")) {
            if (modStartsWith("TMT")) {
                return {
                    ...defaultIdentificationProtocolSelection,
                    phospho: {
                        ...group.identificationProtocolSelection.phospho,
                        applicable: true
                    },
                    tmt: {
                        ...group.identificationProtocolSelection.tmt,
                        applicable: true
                    }
                }
            } else if (modStartsWith("ITRAQ")) {
                return {
                    ...defaultIdentificationProtocolSelection,
                    phospho: {
                        ...group.identificationProtocolSelection.phospho,
                        applicable: true
                    },
                    iTRAQ: {
                        ...group.identificationProtocolSelection.iTRAQ,
                        applicable: true
                    }
                }
            } else {
                return {
                    ...defaultIdentificationProtocolSelection,
                    phospho: {
                        ...group.identificationProtocolSelection.phospho,
                        applicable: true
                    }
                }
            }
        }
        if (modStartsWith("TMT")) {
            return {
                ...defaultIdentificationProtocolSelection,
                tmt: {
                    ...group.identificationProtocolSelection.tmt,
                    applicable: true
                }
            }
        }
        else if (modStartsWith("ITRAQ")) {
            return {
                ...defaultIdentificationProtocolSelection,
                iTRAQ: {
                    ...group.identificationProtocolSelection.iTRAQ,
                    applicable: true
                }
            }
        }

        return {...defaultIdentificationProtocolSelection}
    }

    return  {
        ...group,
        identificationProtocolSelection: f()
    }
}

const validateGroup = group => {

    const errorList = _
        .chain(group)
        .toPairs()
        .map(([groupPropKey, v]) => {

            const msg = ()=> {
                switch (groupPropKey) {
                    case "id":
                    case "samples":
                    case "errors":
                    case "sampleType":
                    case "identificationProtocolSelection":
                        return null
                    case "contactEmail":
                        if(!v) {
                            return "Please enter a contact email"
                        }
                    case "fragmentation":
                    case "msInstrument":
                    case "species" : {
                        if (!v) {
                            return `select ${groupPropKey}`
                        }
                        return null
                    }
                    case "mods":
                    case "userMods": {
                        return null
                    }
                    case "enzymes": {
                        if (v.length === 0) {
                            return "select enzymes"
                        }
                        return null
                    }
                }
            }

            return [groupPropKey, msg()]
        })
        .value()

    const errorDict = _.chain(errorList).fromPairs().value()

    const hasErrors = errorList.filter(([k,v])=> v !== null)

    return {
        hasErrors: hasErrors.length > 0,
        ...errorDict
    }
}


const tdS = () => <td style={{"borderLeftStyle": "none", "borderRightStyle": "none"}}></td>

const SAMPLE_TABLE_COL_COUNT = 3
const spaceRow = () => <tr style={{backgroundColor: "white"}}>
  {tdS()}{tdS()}{tdS()}
</tr>


const modalFrame = ({cancelFunc, title, body, buttons}) =>
    <div className="modal is-active">
        <div className="modal-background" onClick={cancelFunc}></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={cancelFunc}></button>
            </header>
            <section className="modal-card-body">
                <div className="panel">
                    {body}
                </div>
            </section>
            <footer className="modal-card-foot">
                {buttons}
            </footer>
        </div>
    </div>


const PopupEditSample = ({group, sample, dispatch}) => {

    const [replicateNumber, setReplicateNumber] = useState(sample.replicateNumber)

    const [fractionNumber, setFractionNumber] = useState(sample.fractionNumber)

    const {closeModal} = useContext(modalInfoContext)

    const cancelFunc = () => closeModal()

    const updateSampleProp = (propKey, text) =>
        dispatch({type: "updateGroup", group, key: "updateSample", value: text, args: {sample, propKey}})

    const saveChanges = () => {
        updateSampleProp("replicateNumber", replicateNumber)
        updateSampleProp("fractionNumber", fractionNumber)
        closeModal()
    }

    const field = (label, children) =>
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                {children}
            </div>
        </div>

    return modalFrame({
        title: "Edit Sample",
        cancelFunc,
        body:  <div>
            {field("Replicate Number", <input
                        className="input"
                        type="text"
                        value={replicateNumber || ""}
                        onChange={e => setReplicateNumber(e.target.value)}
            />)}
            {field("Fraction Number", <input
                        className="input"
                        type="text"
                        value={fractionNumber || ""}
                        onChange={e => setFractionNumber(e.target.value)}
            />)}
        </div>,
        buttons: <>
                <button onClick={saveChanges} className="button is-success">Save changes</button>
                <button onClick={cancelFunc} className="button">Cancel</button>
        </>
    })
}

const PopupForModifications = ({group, onSave, propKey, modList}) => {

    const [selectedModsByIds, setSelectedModsByIds] = useState(
        _.chain(group[propKey]).map(m => [m.id, m]).fromPairs().value()
    )

    const {closeModal} = useContext(modalInfoContext)

    const cancel = () => closeModal()

    const saveChanges = () => {
        onSave(_.values(selectedModsByIds))
        closeModal()
    }

    const isChecked = m => {
        return !! selectedModsByIds[m.id]
    }

    const toggleAdded = m => {
       if(!isChecked(m)) {
           setSelectedModsByIds({...selectedModsByIds, [m.id]: m})
       }
       else {
           setSelectedModsByIds(_.pickBy(selectedModsByIds, _m => _m.id !== m.id))
       }
    }

    return modalFrame({
        cancelFunc: cancel,
        title: "Edit modifications in sample group",
        body:modList.map(m =>
            <a
                onClick={() => toggleAdded(m)}
                className={`panel-block ${isChecked(m) ? 'has-background-grey-lighter': ''}`}
                key={m.id}
            >
                <label>
                    <span className="panel-icon"><i className="fas fa-code-branch" aria-hidden="true"></i></span>
                     {m.name}
                </label>
            </a>
        ),
        buttons: <>
                <button onClick={saveChanges} className="button is-success">Save changes</button>
                <button onClick={cancel} className="button">Cancel</button>
        </>
    })
}



const SampleGroupEditor = ({group, dispatchUpdateGroup, pxdFormState, allSpecies}) => {

    if(!group) {
        throw Error("group can't be falsy")
    }

    const groupErrors = group.errors

    const dropdown = (propKey, label, choices, choiceDisplay, choiceId) => {


        const getValue = () => {
            const v = group[propKey]
            return v ? choiceId(v): ""
        }

        return <div className="field">
            <label className="label is-small">{label}</label>
            <div className="control">
                <div className={`select is-small ${groupErrors[propKey] ? "is-danger": ""}`}>
                    <select value={getValue()} onChange={e => {
                        const selectedChoice = choices.find(c => choiceId(c) == e.target.value)
                        dispatchUpdateGroup(group, propKey, selectedChoice)
                    }}
                    >
                        <option key={"none"} value={""}></option>
                        {choices.map(c => <option key={propKey+c.id} value={c.id}>{choiceDisplay(c)}</option>)}
                    </select>
                </div>
            </div>
        </div>
    }

    const textGroupProperty = (propKey, label, placeholder) =>
        <div className="field">
            <label className="label is-small">{label}</label>
            <div className="control">
                <input
                    className="input is-small"
                    type="text"
                    placeholder={placeholder}
                    value={group[propKey] || ""}
                    onChange={e => {
                        dispatchUpdateGroup(group, propKey, e.target.value)
                    }}
                />
            </div>
        </div>

    const identificationProtocolSelectionSection = (key, question) => {

        if(! group.identificationProtocolSelection[key].applicable) {
            return null
        }

        const b = group.identificationProtocolSelection[key].value

        const [choiceYes, choiceNo] = b === null ?
            [false, false] :
            [b, !b]

        const uPd = bool => dispatchUpdateGroup(
            group, "identificationProtocolSelection", bool, {key}
        )

        let msg = null

        if(key === 'iTRAQ' && choiceNo) {
            msg = 'Verify your modifications'
        }
        else if(key === 'tmt' && choiceNo) {
            msg = 'Verify your modifications'
        }

        return  <div className="column">
                <div className="control">
                    <label style={{marginRight: 16}} >{question}</label>
                    <label className="radio">
                        <input type="radio" checked={choiceYes} onChange={() => uPd(true)}/>Yes
                    </label>
                    <label className="radio">
                        <input type="radio" checked={choiceNo} onChange={() => uPd(false)}/>No
                    </label>
                </div>
                {msg && <p className={"help is-danger"}>{msg}</p>}
        </div>
    }

    const groupEditor = () => <>
        <div className="columns">
            <div className="column">
                {dropdown("species", "Species", allSpecies, o => o.name, o => o.id)}
                {/*protocolIdentificationField()*/}
            </div>
            <div className="column">
                {textGroupProperty("sampleType","Sample Type", "Toe skin...")}
            </div>
            <div className="column">
                {dropdown("fragmentation", "Fragmentation", fragmentations, o => o.name, o => o.id)}
            </div>
            <div className="column">
                {dropdown("msInstrument", "MS Instrument", pxdFormState.msInstrumentChoices, o => o.name, o => o.id)}
            </div>
        </div>
        <div className="columns">
            {identificationProtocolSelectionSection('phospho', 'Are your samples phospho-enriched')}
            {identificationProtocolSelectionSection('tmt', 'Are your samples TMT labelled')}
            {identificationProtocolSelectionSection('iTRAQ', 'Are your samples iTRAQ labelled')}
        </div>
    </>

    return groupEditor()
}

const MainPDXFields = ({pxdFormState, onUpdate}) => {

    const mainForm = pxdFormState.mainForm

    return <>
        <div className="columns">
            <div className="column">
                <div className="field">
                    <label className="label">PMID</label>
                    <div className="control">
                        <input className="input" type="text" disabled={true} readOnly={true} value={mainForm.pmid}/>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="field">
                    <label className="label">Contact Email</label>
                    <div className="control has-icons-left has-icons-right">
                        <input
                            className={`input ${pxdFormState.missingContactEmail ? "is-danger": ""}`}
                            type="email"
                            placeholder="profA@example.org"
                            onChange={e => onUpdate('contactEmail', e.target.value)}
                            value={mainForm.contactEmail}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      <div className="columns">
            <div className="column">
                <div className="field">
                    <label className="label">Citation</label>
                    <div className="control">
                        <textarea
                            className="textarea"
                            value={mainForm.citation}
                            disabled={true}
                            rows={2}
                            readOnly={true}/>
                    </div>
                </div>
            </div>
        </div>
     </>
}

const IS_DEV_PRE_INIT = false

const SubmitPXDForMS = ({initialPxdFormState, onCancel}) => {

    const {openProtGlobalState} = useOpenProtGlobalState()

    const [pxdField, setPXDField] = useState("")

    const [pxdFetchInProgress, setPXDFetchInProgress] = useState(false)

    const [pxdFormState, dispatch] = useReducer(pxdFormReducer, initialPXDState())

    const [selectedGroupId, setSelectedGroupId] = useState(null)

    const [fetchError, setFetchError] = useState(null)

    const {openModal, closeModal} = useContext(modalInfoContext)


    useEffect(() => {

        if(!initialPxdFormState) {
            return
        }

        setPXDFetchInProgress(true)

        pxdUrlFetcher(initialPxdFormState.studyId)
        .then(doc => {
            setPXDFetchInProgress(false)
            const d = extractInfosFromPDXForMS(doc)
            dispatch({type: "initFromSaved", initialPxdFormState, extractedPXDInfos: d})
        })
        .catch(err => {
            setPXDFetchInProgress(false)
            somethingWentWrong()
            console.log(err)
        })
    },[])

    const somethingWentWrong = () =>
        setFetchError({
            title:"Error",
            message:"Something went wrong, please contact us.",
            messageClass: "is-danger",
            onClose: cancelStudy
        })

    if(openProtGlobalState === null) {
        return <div>Loading...</div>
    }

    const allSpecies = openProtGlobalState.lastRelease.allSpecies
    
    const selectedGroup =
        selectedGroupId === null ? null :
            pxdFormState.groups.find(g => g.id === selectedGroupId)

    const submitStudy = async () => {

        const toSubmit = {
            mainForm: pxdFormState.mainForm,
            groups: pxdFormState.groups,
            studyId: pxdFormState.studyId
        }

        const res = await submitMsStudy(toSubmit)

        if(res.error) {
            setAlreadySubmitted()
            return
        }

        dispatch({type: 'setSavedId', id: res.id})
    }

    const setAlreadySubmitted = isInRelease =>
        setFetchError({
            title:"Study already submitted",
            message: <div>
                <div>Study {pxdField} was previously submitted to OpenProt</div>
                {isInRelease &&
                    <a href={"/p/queryStudy/"+pxdField}>Follow this link to search proteins in this study</a>
                }
            </div>,
            messageClass: "is-warning",
            onClose: cancelStudy
        })

    const cancelStudy = () => {
        dispatch({type: "reset"})
        setFetchError(null)
        if(onCancel) {
            onCancel()
        }
    }

    const samplePicker = () => <div>
        {centeringCol("is-half", <>

            <button
                className="button"
                disabled={! pxdFormState.hasSampleSelected}
                onClick={() => dispatch({type: "groupSelectedSamples"})}
            >
                Group Selected
            </button>
            <button
                className="button"
                disabled={! pxdFormState.hasSampleSelected}
                onClick={() => dispatch({type: "assignSelectedStatus", pred: s => true, isSelected: false})}
            >
                Clear Selection
            </button>
            <button
                className="button"
                disabled={! pxdFormState.hasSampleSelected}
                onClick={() => dispatch({type: "excludeSelectedSamples"})}
            >
                Exclude Selection
            </button>
            <button
                className="button"
                onClick={() => dispatch({type: "assignSelectedStatus", pred: s => ! s.isExcluded, isSelected: true})}
            >
                Select All
            </button>
            <button
                className="button"
                disabled={! selectedGroup}
                onClick={() => dispatch({type: "updateGroup", group: selectedGroup, key: "addSamples"})}
            >
                Add to Selected Group
            </button>
            {pxdFormState.numberOfExcludedSamples > 0 &&
            <button
                className="button"
                onClick={() => dispatch({type: "unExclude"})}
             >
                <span>{pxdFormState.numberOfExcludedSamples} samples(s) are excluded, click to unexclude</span>
            </button>}
        </>)}
        <div className="buttons">
            {pxdFormState.sampleHoldersToSelect.filter(h => ! h.isExcluded).map(h =>
                <button
                    key={h.sample.name}
                    className={`button is-small ${h.isSelected ? "is-link is-light": ""}`}
                    onClick={() => dispatch({
                        type: "assignSelectedStatus",
                        pred: s => s.sample.id === h.sample.id, isSelected: ! h.isSelected
                    })}
                >{h.sample.label}
                </button>
            )}
        </div>
    </div>


    const receivePxdDoc = (pxdId, doc) => {
        const d = extractInfosFromPDXForMS(doc)
        dispatch({type: "initFromSampleList", extractedPXDInfos: d, studyId: pxdId})
    }

    if(! pxdFormState.initialized && IS_DEV_PRE_INIT) {
        const id = "PXD017983"
        setPXDField(id)
        receivePxdDoc(id, samplePXDInJSON)
    }

    const clickFetchPXD = () => {

        //if(pxdField === "PXD017983") {
        //    receivePxdDoc(pxdField, samplePXDInJSON)
        //    return
        //}

        setPXDFetchInProgress(true)

        studyExists("ms", pxdField).then(res => {

            if(res.exists) {
                setAlreadySubmitted(res.released)
                setPXDFetchInProgress(false)
                return
            }

            pxdUrlFetcher(pxdField)
            .then(doc => {
                setPXDFetchInProgress(false)
                if(doc.error) {
                    setFetchError({
                        title:"Study not found",
                        message: doc.error,
                        messageClass: "is-warning",
                        onClose: cancelStudy
                    })
                    return
                }
                receivePxdDoc(pxdField, doc)
            })
            .catch(err => {
                console.log(err)
                setPXDFetchInProgress(false)
                somethingWentWrong()
            })
        })
    }

    const sampleGroupAttributesTop = group => {
        const errors = group.errors

        const msg = () => errors.hasErrors ?
            <>
            <span className="icon has-text-warning">
                <i className="fas fa-info-circle"></i>
            </span>
                Sample group not ready to submit
            </>:
            <>
                <span className="icon has-text-success">
                    <i className="fas fa-check-square"></i>
                </span>
                Sample group ready
            </>

      return msg()
    }

    const sampleGroupAttributesBottom = group => {

      const attribs = [
          [group.fragmentation, "Fragmentation ?", f => f.name]
      ].map(([object, labelWhenNull, displayFunc]) => {

          if (object) {
              return <span className="tag is-link is-light" key={displayFunc(object)}>{displayFunc(object)}</span>
          }

          return <span className="tag is-danger is-light" key={labelWhenNull}>{labelWhenNull}</span>
      })

      return <>
          <SampleGroupEditor
              dispatchUpdateGroup={dispatchUpdateGroup}
              group={group}
              allSpecies={allSpecies}
              pxdFormState={pxdFormState}
          />
      </>
    }


    const tagSelector = (group, tagLabel, tagChoices, propKey) =>
        <div className="field">
            <label className="label is-small">{tagLabel}</label>
            <div className="control">
                <div className="select is-small">
                    <select value={""} defaultValue={""}  onChange={e =>
                        dispatchUpdateGroup(group, propKey, JSON.parse(e.target.value), {isAdd: true})
                    }>
                        <option key={"none"} value={""}></option>
                        {tagChoices.map(c => <option key={propKey+c.id} value={JSON.stringify(c)}>{c.name}</option>)}
                    </select>
                </div>
            </div>
        </div>


    const SamplePropEditField = ({group, sample, propKey, label}) => {

        const updateSampleProp = (text) =>
            dispatch({type: "updateGroup", group, key: "updateSample", value: text, args: {sample, propKey}})

        return <div className="field has-addons">
            <div className="control">
                <a className="button is-static is-small">fraction</a>
            </div>
            <div className="control">
                <input
                    className="input is-small"
                    type="text"
                    value={sample[propKey] || ""}
                    autoFocus
                    onChange={e => {
                        updateSampleProp(e.target.value)
                    }}
                />
            </div>
        </div>
    }


    const SamplePill = ({group, sample}) => {

        return <div className="tags has-addons ">
            <span className="tag is-rounded  is-medium">{sample.name}</span>
            <span className="tag is-medium is-info is-light">Fraction {sample.fractionNumber}</span>
            <span className="tag is-medium is-info is-light">Replicate {sample.replicateNumber}</span>
            <a
              className="tag is-medium"
              onClick={e => {
                  e.stopPropagation()
                  openModal(() => <PopupEditSample group={group} sample={sample} dispatch={dispatch}/>)
              }}
            >Edit</a>
            <a
              className="tag is-delete is-medium  is-rounded"
              onClick={e => {
                  e.stopPropagation()
                  dispatch({type: "removeSampleFromGroup", group, sample})
              }}
            ></a>
        </div>
    }
    const tableStyle = {
        width: "100%"
        //width: -moz-available;
        //width: -webkit-fill-available;
        //width: fill-available;
    }

    const sampleTable = () =>
      <table className="table is-bordered" style={tableStyle}>
          <thead>
            <tr>
                <th>Samples</th>
                <th>Enzymes</th>
                <th>Modifications</th>
            </tr>
          </thead>
          {
              pxdFormState.groups.map(group => {

                  const isGroupSelected = group.id == selectedGroupId

                  const bodyStyle = ! isGroupSelected ? {} : {backgroundColor: "rgba(243, 243, 243, 0.66)"}

                  return <tbody
                      key={group.id}
                      style={bodyStyle}
                      onClick={() => setSelectedGroupId(group.id)}
                  >
                  <tr>
                      <td colSpan={SAMPLE_TABLE_COL_COUNT}>
                          {sampleGroupAttributesTop(group)}
                      </td>
                  </tr>
                  {
                      group.samples.map((sample, idx) =>
                          <tr key={"sample_row_"+sample.id}>
                              <td>
                                  <SamplePill sample={sample} group={group}/>
                              </td>
                              {
                                  idx > 0 ? null : <>
                                    <td rowSpan={group.samples.length}>
                                      {group.enzymes.map(enz => tag(group, "enzymes", enz))}
                                      {tagSelector(group, "Add Enzyme", enzymes, "enzymes")}
                                    </td>
                                    <td rowSpan={group.samples.length}>
                                        <div className={"box"}>{group.mods.map(mod => tag(group, "mods", mod, "is-info is-light"))}
                                            <br/>
                                            <button className="button is-small is-info is-light" onClick={() =>
                                              openModal(() =>
                                                  <PopupForModifications
                                                      group={group}
                                                      modList={msMods}
                                                      propKey={"mods"}
                                                      onSave={selectedMods => dispatchUpdateGroup(
                                                          group, "mods", selectedMods, {isAdd: true}
                                                      )}
                                                  />)}
                                            >Edit Variable Modifications
                                            </button>
                                        </div>
                                        <div className={"box"}>{group.userMods.map(mod => tag(group, "userMods", mod, "is-primary is-light"))}
                                            <br/>
                                            <button className="button is-small is-primary is-light" onClick={() =>
                                              openModal(() =>
                                                  <PopupForModifications
                                                      group={group}
                                                      modList={msMods}
                                                      propKey={"userMods"}
                                                      onSave={selectedMods => dispatchUpdateGroup(
                                                          group, "userMods", selectedMods, {isAdd: true}
                                                      )}
                                                  />)}
                                            >Edit Fixed Modifications
                                            </button>
                                        </div>
                                    </td>
                                  </>
                              }
                          </tr>
                      )
                  }
                  <tr>
                      <td colSpan={SAMPLE_TABLE_COL_COUNT}>
                          {sampleGroupAttributesBottom(group)}
                      </td>
                  </tr>
                  {spaceRow()}
                  </tbody>
              })
          }
      </table>

    const dispatchUpdateGroup = (group, key, value, args) => dispatch({
        type:"updateGroup",
        group, key, value, args
    })

    const tag = (group, propKeyInGroup, tagObject, colorClass) =>
        <span className= {"tag "+colorClass} key={tagObject.id} style={{marginTop: 4}}>
            {tagObject.name}
            <button className="delete is-small"
                    onClick={e => {
                        e.stopPropagation();
                        dispatchUpdateGroup(group, propKeyInGroup, tagObject, {isAdd: false})
                    }}
            />
        </span>

    const submitMessage = () => {

        const m = () => {

            if(pxdFormState.missingContactEmail) {
                return ["Please enter a contact email", false]
            }
            if (! pxdFormState.allSamplesGroupedOrExcluded) {
                return ["All samples must be grouped before submit", false]
            }
            if (pxdFormState.hasGroupInError) {
                return ["Some sample groups have missing information, can't submit yet ", false]
            }

            return ["ready to submit", true]
        }

        const [txt, isReady] = m()

        const messageClass =  ! isReady ? "is-warning" : "is-success"

        return <article className={`message ${messageClass}`}>
            <div className="message-body">
                {txt}
            </div>
        </article>
    }

    const formBox = () =>
         <div className="box">
          <div className="title is-3">Study {pxdField}</div>
          {pxdFormState.initialized &&
            <MainPDXFields
                pxdFormState={pxdFormState}
                onUpdate={(fieldKey, fieldValue) => dispatch({type: "updateMainFormField", fieldKey, fieldValue})}
            />
          }
          <div className="title is-3">Samples</div>
            <div className="columns">
                <div className="column">
                    {submitMessage()}
                    {samplePicker()}
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    {sampleTable()}
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    {submitMessage()}
                </div>
            </div>
            <div className="columns is-mobile is-centered">
                <div className="column is-one-third">
                    <div className="columns">
                        <div className="column">
                            {(! pxdFormState.id) &&
                                <button className="button is-primary is-large" onClick={submitStudy} disabled={! pxdFormState.canSubmitStudy}>
                                    Submit Study
                                </button>
                            }
                            {pxdFormState.id &&
                                <button className="button is-primary is-large" onClick={() => {}} disabled={! pxdFormState.canSubmitStudy}>
                                    Save Study
                                </button>
                            }
                        </div>
                        <div className="column">
                          <button className="button is-large" onClick={cancelStudy}>
                              Cancel
                          </button>
                        </div>
                    </div>
                </div>
            </div>
         </div>

    const fetchPanel = () => pxdFormState.initialized ? null :
        centeringCol('is-one-third', <>
          <div className="field has-addons">
              <div className="control">
                  <input className="input" type="text" value={pxdField}
                    onChange={e => setPXDField(e.target.value)}  placeholder="ex: PXD017983"/>
              </div>
              <div className="control">
                  <button className="button is-info" onClick={clickFetchPXD} disabled={! pxdField}>
                      Load PXD
                  </button>
              </div>
          </div>
          {pxdFetchInProgress &&
            <progress className="progress is-primary is-large" max="100">15%</progress>
          }
        </>)

    if(fetchError) {
        return centeringCol("is-half", <>
            <article className={`message ${fetchError.messageClass}`}>
                <div className="message-header">
                    <p>{fetchError.title}</p>
                    <button onClick={() => fetchError.onClose()} className="delete" aria-label="delete"></button>
                </div>
                <div className="message-body">
                    {fetchError.message}

                    <button
                        className="button"
                        onClick={() => fetchError.onClose()}
                    >Ok</button>
                </div>
            </article>
        </>)
    }

    if(pxdFormState.saveSuccessful) {
        return centeringCol("is-half", <>
            <article className="message">
                <div className="message-header">
                    <p>Study Submitted</p>
                    <button className="delete" aria-label="delete"></button>
                </div>
                <div className="message-body">
                    <div>
                        Study {pxdField} has been submitted.

                        You will receive a confirmation email from us (please check your spam folder if you don't see it in your inbox).
                    </div>

                    <button
                        className="button"
                        onClick={cancelStudy}
                    >Ok</button>
                </div>
            </article>
        </>)
    }

    return <>
      <MainMenu/>
      {fetchPanel()}
      {pxdFormState.initialized && formBox()}
    </>
}

export default SubmitPXDForMS