
import React, {useState, useCallback, useContext} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Home from "./screens/Home";
import SubmittedStudies from "./screens/SubmittedStudies";
import SubmitRiboSeqStudy from "./screens/SubmitRiboSeqStudy";
import ContactUs from "./screens/ContactUs";
import SubmitPXDForMS from "./screens/SubmitPXDForMS";

export const useModalInfo = () => {

    const [modalRenderer, setModalRenderer] = useState({f: null})

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = useCallback((renderer) => {
        setModalRenderer({f: renderer})
        setIsModalOpen(true)
    },[])

    const closeModal = useCallback(() => {
        setModalRenderer({f: null})
        setIsModalOpen(false)
    },[])

    return {
        openModal,
        closeModal,
        isModalOpen,
        renderModal: () => modalRenderer.f ? modalRenderer.f(): null
    }
}

export const modalInfoContext = React.createContext()

const ModaleParent = ({children}) => {

    const {renderModal, isModalOpen} = useContext(modalInfoContext)

    return <div className={`container ${isModalOpen ? 'is-clipped': ''}`}>
        {children}
        {renderModal()}
    </div>
}

const router = () =>
    <Router>
        <Switch>
          <Route path="/p/ng/submitRiboStudy">
            <SubmitRiboSeqStudy/>
          </Route>
          <Route path="/p/ng/submitMsStudy">
            <SubmitPXDForMS/>
          </Route>
          <Route path="/p/ng/contactUs">
            <ContactUs/>
          </Route>
          <Route path="/">
            <Home/>
          </Route>
        </Switch>
    </Router>

const App = () => {

    const modalInfo = useModalInfo()

    return <modalInfoContext.Provider value={modalInfo}>
      <ModaleParent>
          {router()}
      </ModaleParent>
    </modalInfoContext.Provider>
}


export default App
