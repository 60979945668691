import React from "react";
import {MainMenu} from "../uiComponents/MainMenu";
import {SearchByGeneAndSpecies, useSearchByGeneAndSpeciesState} from "../uiComponents/LandingPageSearch";

const SponsorLogos = ({marginTop}) =>
    <div className={"columns"} style={{marginTop: marginTop}}>
        <div className={"column"}> <img src={"/static/images/udes.jpg"}/></div>
        <div className={"column"}> <img src={"/static/images/compute_canada_logo.png"}/></div>
        <div className={"column"}> <img style={{height: 74, paddingLeft: 32}} src={"/static/images/CalculQuebec_logo_large.jpg"}/></div>
        <div className={"column"}> <img src={"/static/images/CRC_Logo.png"}/></div>
    </div>


const Home = () => {

    const searchByGeneAndSpeciesState = useSearchByGeneAndSpeciesState()

    const openProtRelease = searchByGeneAndSpeciesState.lastReleaseMetadata &&
        searchByGeneAndSpeciesState.lastReleaseMetadata.openProtRelease

    const downloadUrl =
        openProtRelease ?
        `/download/files/1.6/fasta_library/human-openprot-r1_6-altprots-isoforms-2peptides+uniprot2019_03_01.fasta.zip`
            : ''

    return <>
        <MainMenu/>
        <div className='column is-10 is-offset-1 mt-6' style={{height:300}}>
            <h1 className='title has-text-centered is-spaced has-text-link'>What if RNA transcripts encoded more proteins than are currently annotated in common databases?</h1>
            <h2 className='subtitle has-text-centered has-text-grey-dark mb-1'> OpenProt proposes a <span className='has-background-primary'>comprehensive annotation</span> of predicted coding sequences on all transcripts. 
            It also publishes results from large scale searches of public data providing <span className='has-background-primary'>evidence for the expression of novel protein</span> products. </h2>
            <p className='has-text-centered is-size-7'><a target="_blank" href="https://doi.org/10.1093/nar/gky936">Nucleic Acids Research 2019</a>, <a target="_blank" href="https://doi.org/10.1093/nar/gkaa1036">Nucleic Acids Research 2021</a></p>
        </div>
        <div className='column is-10 is-offset-1'>
            <div className='columns' >
                <div className='column is-one-third'>
                    <SearchByGeneAndSpecies searchByGeneAndSpeciesState={searchByGeneAndSpeciesState}/>
                </div>
                <div className='column'>
                    <button
                        className="button is-link"
                        onClick={() => {
                            document.location.href =
                                `/p/altorfDbView?g=${searchByGeneAndSpeciesState.geneSymbol}&s=${searchByGeneAndSpeciesState.selectedSpeciesId}`
                        }}
                    >Search
                    </button>
                </div>
                <div className='column has-text-right'>
                    <div>
                        <a
                            className="button is-link is-fullwidth"
                            disabled={! openProtRelease}
                            href={downloadUrl}
                        >
                            Download
                        </a>
                        <div className='has-text-centered'><a href="/p/download">more options</a></div>
                    </div>
                </div>
                <div className='column is-one-third'>
                    <p className="is-size-7">Our <span className='has-text-weight-bold'>human protein library</span> aimed at the detection
                        of the most confident alternative proteins contains ~77k
                        canonical proteins from UniProtKB along with the ~4k alternative proteins showing the strongest evidence of expression.
                        See the <a href="/p/download">download section</a> for more options.</p>
                </div>
            </div>
        </div>
        <SponsorLogos marginTop={30}/>

        <div className='content has-background-grey-lighter p-4 ' >
            <h2 className='subtitle has-text-link mt-2'>OpenProt discoveries: <span style={{"textDecoration":"underline", "textDecorationColor":"#30B0D5"}}>re-interpret</span> already acquired data</h2>
            <p>The annotation of sequences is central to current research in biomolecular sciences. The addition of unannotated protein sequences in the OpenProt protein library has resulted in many important discoveries in the human proteome through the re-analysis of publicly available data. Many of these have been selected for further investigation:</p>
            <div className='columns'>
                <div className='column is-half'>
                    <ul>
                        <li>The FUS gene is dual-coding with both proteins contributing to FUS-mediated toxicity. <a target="_blank" href="https://doi.org/10.15252/embr.202050640">https://doi.org/10.15252/embr.202050640</a></li>
                        <li>The Protein Coded by a Short Open Reading Frame, Not by the Annotated Coding Sequence, Is the Main Gene Product of the Dual-Coding Gene MIEF1. <a target="_blank" href="https://doi.org/10.1074/mcp.RA118.000593">https://doi.org/10.1074/mcp.RA118.000593</a></li>
                        <li>Potentiation of B2 receptor signaling by AltB2R, a newly identified alternative protein encoded in the human bradykinin B2 receptor gene. <a target="_blank" href="https://doi.org/10.1016/j.jbc.2021.100329">https://doi.org/10.1016/j.jbc.2021.100329</a></li>
                    </ul>                    
                </div>
                <div className='column is-half'>
                    <ul>
                        <li>UBB pseudogene 4 encodes functional ubiquitin variants. <a target="_blank" href="https://doi.org/10.1038/s41467-020-15090-6">https://doi.org/10.1038/s41467-020-15090-6</a></li>
                        <li>An overlapping reading frame in the PRNP gene encodes a novel polypeptide distinct from the prion protein. <a target="_blank" href="https://doi.org/10.1096/fj.10-173815">https://doi.org/10.1096/fj.10-173815</a></li>
                        <li>An out-of-frame overlapping reading frame in the ataxin-1 coding sequence encodes a novel ataxin-1 interacting protein. <a target="_blank" href="https://doi.org/10.1074/jbc.M113.472654">https://doi.org/10.1074/jbc.M113.472654</a></li>
                    </ul>                    
                </div>
            </div>
            <p>We invite the scientific community to join in the search! A tutorial for OpenProt is available at <a target="_blank" href="https://dx.doi.org/10.3791/59589">JoVE Journal</a>.</p>
            <h2 className='subtitle has-text-link mt-2'><span style={{"textDecoration":"underline", "textDecorationColor":"#30B0D5"}}>The concept</span> behind OpenProt</h2>
            <h3>Current annotations</h3>
            <div className='columns'>
                <div className='column is-two-fifths'>
                    <p>
                        Current genome annotations hold limiting criteria for Open Reading Frames (ORF) including a minimal ORF length of 100 codons and a single ORF per transcript.
                        Transcripts that do not meet these criteria are labeled non-coding (ncRNAs) and transcripts from unprocessed pseudogenes are also systematically annotated non-coding.
                    </p>
                </div>
                <div className='column is-three-fifths'>
                    <img src="/static/images/current_model.png"/>
                </div>
            </div>
            <h3>OpenProt annotations</h3>
            <div className='columns'>
                <div className='column is-two-fifths'>
                    <p>
                    OpenProt relaxes traditional annotation criteria by including all ORFs longer than 30 codons and allowing multiple ORFs per transcript as well as those encoded in ncRNAs and transcripts of pseudogenes. 
                    OpenProt offers a deeper description and thus a more realistic and biologically relevant perspective of the proteome.
                    </p>
                </div>
                <div className='column is-three-fifths'>
                    <img src="/static/images/OpenProt_model.png"/>
                </div>
            </div>
            <h2 className='subtitle has-text-link'>The OpenProt <span style={{"textDecoration":"underline", "textDecorationColor":"#30B0D5"}}>pipeline</span></h2>
            <h3>Prediction pipeline</h3>
            <div className='columns is-flex is-vcentered'>
                <div className='column is-half'>
                    <img src="/static/images/OpenProt_pipeline.png"/>
                </div>
                <div className='column is-half'>
                    <p>The OpenProt ORF prediction pipeline starts from an exhaustive description of the transcriptome consisting of all RNA transcripts reported by both Ensembl and NCBI RefSeq. 
                        A 3-frame in silico translation then yields the ORFeome: any ORF longer than 30 codons in any frame of any transcript.  
                        This ORFeome is then filtered to categorize predicted ORFs. 
                        The first filter retrieves all known proteins, or reference proteins (all ORF already annotated in Ensembl, NCBI RefSeq, and/or UniProtKB). 
                        The second filter is based on the homology of currently not annotated ORFs with the refProt of the same gene (if applicable), and retrieves novel predicted isoforms. 
                        The remaining ORFs encode novel proteins, called alternative proteins (altProts).</p>
                </div>
            </div>

            <h3>Evidence pipeline</h3>
            <div className='columns is-flex is-vcentered'>
                <div className='column is-half'>
                    <img src="/static/images/OpenProt_evidence_pipeline.png"/>
                </div>
                <div className='column is-half'>
                    <p>OpenProt also gathers and shares evidence for the significance and expression of novel ORFs:</p>
                    <ul>
                        <li><span className="has-text-weight-semibold">Conservation evidence</span>: for every ORF annotated, OpenProt identifies orthologs and paralogs (across the 10 species currently supported by OpenProt).</li>
                        <li><span className="has-text-weight-semibold">Translation evidence</span>: Publicly available ribosome profiling datasets are re-analysed using the Price algorithm. This gathers translation evidence for any ORF annotated in OpenProt.</li>
                        <li><span className="has-text-weight-semibold">Expression evidence</span>: Publicly available mass spectrometry datasets are re-analysed using multiple search engines. This gathers expression evidence for any ORF annotated in OpenProt.</li>
                    </ul>
                </div>
            </div>
            <div></div>
        </div>
    </>
}

export default Home