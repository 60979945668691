
import React from "react";
import ReactDOM from "react-dom";
import 'regenerator-runtime/runtime'
require('./styles.scss')

import App from "./App"

const mountNode = document.getElementById("app");

ReactDOM.render(<App/>, mountNode);

