import React from "react";

export const MainMenu = () =>
<nav className="navbar is-transparent">
    <div className="navbar-brand" style={{height:80}}>
        <a className="navbar-item" href="/p/ng/Home">
            <img src="/static/images/logo_home_crop.png"/>
        </a>
        <div className="navbar-burger burger">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div className="navbar-menu">
        <div className="navbar-end">
            <a className="navbar-item has-text-link" href="/p/altorfDbView">Search</a>
            <a className="navbar-item has-text-link" href="/p/download">Download</a>
            <a className="navbar-item has-text-link" href="/p/help">Documentation</a>

            <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">☰</a>
                <div className="navbar-dropdown">
                    <a className="navbar-item has-text-link" href="/p/browse">Genome Browser</a>
                    <a className="navbar-item has-text-link" href="/p/ng/submitRiboStudy">Submit Ribo Study</a>
                    <a className="navbar-item has-text-link" href="/p/ng/submitMsStudy">Submit MS Study</a>
                    <a className="navbar-item has-text-link" href="/p/ng/contactUs">Contact</a>
                </div>
            </div>
        </div>
    </div>
</nav>