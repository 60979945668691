
import {centeringCol, validateEmail} from "./utils";
import React, {useState} from "react";
import {post} from "../rest";
import {MainMenu} from "../uiComponents/MainMenu";

const sendContactUs = data =>
    post("/api/2/contactUs", data)

const ContactUs = ({}) => {

    const [email, setEmail] = useState('')

    const [subject, setSubject] = useState('')

    const [message, setMessage] = useState('')

    const [sendProgress, setSendProgress] = useState(0)

    const reset = () => {
        setEmail('')
        setMessage('')
        setSubject('')
        setSendProgress(0)
    }

    const isEmailValid = validateEmail(email)

    const showInvalidEmail = email !== '' && email != null && (!isEmailValid)

    const submit = async () => {

        setSendProgress(1)

        const res = await sendContactUs({
            email,
            subject,
            message
        })

        setSendProgress(2)
    }

    const formz = () => <>
            <h1>Tell us what you think !</h1>
            {sendProgress === 1 && <progress className="progress is-primary is-large" max="100">15%</progress>}
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label className="label">Contact Email</label>
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className={`input ${showInvalidEmail ? "is-danger": ""}`}
                                type="email"
                                placeholder="bob@example.org"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                            <span className="icon is-small is-right">
                              <i className="fas fa-exclamation-triangle"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label className="label">Subject</label>
                        <div className="control">
                            <input
                                className={"input"}
                                type="text"
                                placeholder=""
                                onChange={e => setSubject(e.target.value)}
                                value={subject}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="control">
                        <textarea
                            className="textarea"
                            placeholder="Hi..."
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="control">
                        <button className="button" onClick={() => submit()}>Send</button>
                    </div>
                </div>
            </div>
    </>

    const theEnd = () => <div>
        <h1>Tank you !</h1>
        <button className="button" onClick={() => reset()}>Close</button>
    </div>


    return <>
        <MainMenu/>
        {
        centeringCol("is-half", <div className={"box"}>
            {sendProgress === 2 ? theEnd() : formz()}
        </div>)
        }
    </>
}


export default ContactUs