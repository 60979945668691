
import xml2js from "xml2js";
import _ from "lodash";

export const samplePXDInJSON = {
  "ProteomeXchangeDataset": {
    "$": {
      "id": "PXD017983",
      "formatVersion": "1.4.0",
      "xsi:noNamespaceSchemaLocation": "proteomeXchange-1.4.0.xsd",
      "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance"
    },
    "CvList": [
      {
        "Cv": [
          {
            "$": {
              "fullName": "PSI-MS",
              "uri": "https://raw.githubusercontent.com/HUPO-PSI/psi-ms-CV/master/psi-ms.obo",
              "id": "MS"
            }
          },
          {
            "$": {
              "fullName": "PSI-MOD",
              "uri": "https://raw.githubusercontent.com/MICommunity/psidev/master/psi/mod/data/PSI-MOD.obo",
              "id": "MOD"
            }
          },
          {
            "$": {
              "fullName": "UNIMOD",
              "uri": "http://www.unimod.org/obo/unimod.obo",
              "id": "UNIMOD"
            }
          }
        ]
      }
    ],
    "DatasetSummary": [
      {
        "$": {
          "announceDate": "2020-04-22",
          "hostingRepository": "PRIDE",
          "title": "Immunoprecipitation of PTEN coupled Nano-LC−ESI-MS/MS"
        },
        "Description": [
          "Nuclear localization of PTEN is essential for its tumor suppressive role, and loss of nuclear PTEN is more prominent than cytoplasmic PTEN in many kinds of cancers. However, nuclear PTEN-specific regulatory mechanisms were rarely reported. Based on the finding that nuclear PTEN is more unstable than cytoplasmic PTEN. Thus, 293T cells were transiently transfected with plasmid encoding Flag-tagged PTEN along with empty vector, followed by affinity purification using an anti-Flag antibody, and the bound proteins were analyzed by liquid chromatography with tandem mass spectrometry (LC−MS/MS). Here we identify that F-box only protein 22 (FBXO22) induces ubiquitylation of nuclear but not cytoplasmic PTEN at lysine 221, which is responsible for the degradation of nuclear PTEN."
        ],
        "ReviewLevel": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002854",
                  "name": "Peer-reviewed dataset"
                }
              }
            ]
          }
        ],
        "RepositorySupport": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002856",
                  "name": "Supported dataset by repository"
                }
              }
            ]
          }
        ]
      }
    ],
    "DatasetIdentifierList": [
      {
        "DatasetIdentifier": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1001919",
                  "name": "ProteomeXchange accession number",
                  "value": "PXD017983"
                }
              }
            ]
          },
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1001922",
                  "name": "Digital Object Identifier (DOI)",
                  "value": "10.6019/PXD017983"
                }
              }
            ]
          }
        ]
      }
    ],
    "DatasetOriginList": [
      {
        "DatasetOrigin": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002868",
                  "name": "Original data"
                }
              }
            ]
          }
        ]
      }
    ],
    "SpeciesList": [
      {
        "Species": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1001469",
                  "name": "taxonomy: scientific name",
                  "value": "Homo sapiens (Human)"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1001467",
                  "name": "taxonomy: NCBI TaxID",
                  "value": "9606"
                }
              }
            ]
          }
        ]
      }
    ],
    "InstrumentList": [
      {
        "Instrument": [
          {
            "$": {
              "id": "Instrument_1"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000449",
                  "name": "LTQ Orbitrap"
                }
              }
            ]
          }
        ]
      }
    ],
    "ModificationList": [
      {
        "cvParam": [
          {
            "$": {
              "cvRef": "UNIMOD",
              "accession": "UNIMOD:35",
              "name": "Oxidation"
            }
          },
          {
            "$": {
              "cvRef": "UNIMOD",
              "accession": "UNIMOD:1",
              "name": "Acetyl"
            }
          },
          {
            "$": {
              "cvRef": "UNIMOD",
              "accession": "UNIMOD:4",
              "name": "Carbamidomethyl"
            }
          }
        ]
      }
    ],
    "ContactList": [
      {
        "Contact": [
          {
            "$": {
              "id": "project_submitter"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000586",
                  "name": "contact name",
                  "value": "ge mengkai"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000589",
                  "name": "contact email",
                  "value": "gemengk@sjtu.edu.cn"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000590",
                  "name": "contact affiliation",
                  "value": "SJTU"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002037",
                  "name": "dataset submitter"
                }
              }
            ]
          },
          {
            "$": {
              "id": "project_lab_head"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002332",
                  "name": "lab head"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000586",
                  "name": "contact name",
                  "value": "Chen Guo-Qiang"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000589",
                  "name": "contact email",
                  "value": "chengq@shsmu.edu.cn"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000590",
                  "name": "contact affiliation",
                  "value": "Department of Pathophysiology, Key Laboratory of Cell Differentiation and Apoptosis of Chinese Ministry of Education, Rui-Jin Hospital, and State Key Laboratory of Oncogenes and Related Genes and Chinese Academy of Medical Sciences Research Unit (NO.2019RU043), Shanghai Cancer Institute, Shanghai Jiao Tong University School of Medicine, Shanghai 200025, China."
                }
              }
            ]
          }
        ]
      }
    ],
    "PublicationList": [
      {
        "Publication": [
          {
            "$": {
              "id": "PMID32249768"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1000879",
                  "name": "PubMed identifier",
                  "value": "32249768"
                }
              },
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002866",
                  "name": "Reference",
                  "value": "Ge MK, Zhang N, Xia L, Zhang C, Dong SS, Li ZM, Ji Y, Zheng MH, Sun J, Chen GQ, Shen SM. FBXO22 degrades nuclear PTEN to promote tumorigenesis. Nat Commun. 2020 11(1):1720"
                }
              }
            ]
          }
        ]
      }
    ],
    "KeywordList": [
      {
        "cvParam": [
          {
            "$": {
              "cvRef": "MS",
              "accession": "MS:1001925",
              "name": "submitter keyword",
              "value": "293T,PTEN,immunoprecipitation,Nano-LC−ESI-MS/MS"
            }
          }
        ]
      }
    ],
    "FullDatasetLinkList": [
      {
        "FullDatasetLink": [
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002852",
                  "name": "Dataset FTP location",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983"
                }
              }
            ]
          },
          {
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1001930",
                  "name": "PRIDE project URI",
                  "value": "http://www.ebi.ac.uk/pride/archive/projects/PXD017983"
                }
              }
            ]
          }
        ]
      }
    ],
    "DatasetFileList": [
      {
        "DatasetFile": [
          {
            "$": {
              "id": "FILE_9",
              "name": "PTEN_1.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_1.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_10",
              "name": "PTEN_10.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_10.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_11",
              "name": "PTEN_2.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_2.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_12",
              "name": "PTEN_3.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_3.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_13",
              "name": "PTEN_4.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_4.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_14",
              "name": "PTEN_5.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_5.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_15",
              "name": "PTEN_6.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_6.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_16",
              "name": "PTEN_7.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_7.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_17",
              "name": "PTEN_8.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_8.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_18",
              "name": "PTEN_9.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/PTEN_9.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_20",
              "name": "SSM_EV_1.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_1.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_21",
              "name": "SSM_EV_10.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_10.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_22",
              "name": "SSM_EV_2.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_2.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_23",
              "name": "SSM_EV_3.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_3.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_24",
              "name": "SSM_EV_4.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_4.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_25",
              "name": "SSM_EV_5.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_5.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_26",
              "name": "SSM_EV_6.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_6.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_27",
              "name": "SSM_EV_7.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_7.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_28",
              "name": "SSM_EV_8.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_8.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_29",
              "name": "SSM_EV_9.raw"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002846",
                  "name": "Associated raw file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/SSM_EV_9.raw"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_31",
              "name": "peak.mzid.gz_EV_1.raw_(F004119).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_1.raw_(F004119).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_32",
              "name": "peak.mzid.gz_EV_10.raw_(F004128).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_10.raw_(F004128).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_33",
              "name": "peak.mzid.gz_EV_2.raw_(F004120).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_2.raw_(F004120).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_34",
              "name": "peak.mzid.gz_EV_3.raw_(F004121).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_3.raw_(F004121).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_35",
              "name": "peak.mzid.gz_EV_4.raw_(F004122).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_4.raw_(F004122).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_36",
              "name": "peak.mzid.gz_EV_5.raw_(F004123).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_5.raw_(F004123).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_37",
              "name": "peak.mzid.gz_EV_6.raw_(F004124).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_6.raw_(F004124).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_38",
              "name": "peak.mzid.gz_EV_7.raw_(F004125).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_7.raw_(F004125).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_39",
              "name": "peak.mzid.gz_EV_8.raw_(F004126).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_8.raw_(F004126).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_40",
              "name": "peak.mzid.gz_EV_9.raw_(F004127).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_EV_9.raw_(F004127).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_41",
              "name": "peak.mzid.gz_PTEN_1.raw_(F004129).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_1.raw_(F004129).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_42",
              "name": "peak.mzid.gz_PTEN_10.raw_(F004138).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_10.raw_(F004138).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_43",
              "name": "peak.mzid.gz_PTEN_2.raw_(F004130).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_2.raw_(F004130).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_44",
              "name": "peak.mzid.gz_PTEN_3.raw_(F004131).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_3.raw_(F004131).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_45",
              "name": "peak.mzid.gz_PTEN_4.raw_(F004132).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_4.raw_(F004132).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_46",
              "name": "peak.mzid.gz_PTEN_5.raw_(F004133).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_5.raw_(F004133).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_47",
              "name": "peak.mzid.gz_PTEN_6.raw_(F004134).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_6.raw_(F004134).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_48",
              "name": "peak.mzid.gz_PTEN_7.raw_(F004135).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_7.raw_(F004135).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_49",
              "name": "peak.mzid.gz_PTEN_8.raw_(F004136).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_8.raw_(F004136).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_50",
              "name": "peak.mzid.gz_PTEN_9.raw_(F004137).MGF"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002850",
                  "name": "Peak list file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz_PTEN_9.raw_(F004137).MGF"
                }
              }
            ]
          },
          {
            "$": {
              "id": "FILE_53",
              "name": "peak.mzid.gz"
            },
            "cvParam": [
              {
                "$": {
                  "cvRef": "MS",
                  "accession": "MS:1002848",
                  "name": "Result file URI",
                  "value": "ftp://ftp.pride.ebi.ac.uk/pride/data/archive/2020/04/PXD017983/peak.mzid.gz"
                }
              }
            ]
          }
        ]
      }
    ],
    "RepositoryRecordList": [
      {
        "RepositoryRecord": [
          {
            "$": {
              "name": "Immunoprecipitation of PTEN coupled Nano-LC−ESI-MS/MS",
              "label": "PRIDE project",
              "recordID": "PXD017983",
              "repositoryID": "PRIDE",
              "uri": "http://www.ebi.ac.uk/pride/archive/projects/PXD017983"
            }
          }
        ]
      }
    ]
  }
}

// PXD017983

const submitterEmail = doc => _
    .chain(doc.ProteomeXchangeDataset.ContactList)
    .map(contactList =>
      contactList.Contact.map(c =>
        c.$.id !== "project_submitter" ? null :
          c.cvParam
              .filter(o => o.$.name === "contact email")
              .map(o => o.$.value)
      )
      .filter(x => x)
    )
    .flattenDeep()
    .value()[0]

const citationAndPMID = doc => _
    .chain(doc.ProteomeXchangeDataset.PublicationList)
    .map(publicationList => {

      const p = _.first(publicationList.Publication)
      const pmidTag = cvParam(p, "PubMed identifier")[0]
      if(!pmidTag) {
        return {pmid: "pending", citation}
      }
      const pmid = pmidTag.value
      const citation = cvParam(p, "Reference")[0].value
      return {pmid, citation}
    })
    .flattenDeep()
    .first()
    .value()

const cvParam = (pars, v) => pars.cvParam
    .map(p => p.$.name === v ? p.$ : null)
    .filter(x=>x)

const cvParamF = (pars, f) => pars.cvParam
    .map(p => f(p.$.name) ? p.$ : null)
    .filter(x=>x)

const modifications = doc => _
    .chain(doc.ProteomeXchangeDataset.ModificationList)
    .map(m => m.cvParam.map(p => {

        return _.pick(p.$, "accession", "name")
      })
    ).flatten()
    .value()

const instruments = doc => _
    .chain(doc.ProteomeXchangeDataset.InstrumentList)
    .map(l => l.Instrument.map(i => {
      return cvParamF(i, ()=>true)[0].name
    }))
    .flattenDeep()
    .value()
    .map(i => ({
      id: i,
      name: i
    }))

const validSampleEndings = [".mzxml", ".mzml", ".mgf", ".raw"]

const sampleList = doc => _
    .chain(doc.ProteomeXchangeDataset.DatasetFileList)
    .map(l => l.DatasetFile.map(f => {
      const p = f.cvParam[0].$

      const sampleFileEnding = _.find(validSampleEndings, e => p.value.toLowerCase().endsWith(e))

      if(! sampleFileEnding) {
        return null
      }

      const name = f.$.name
      const idx = name.indexOf(sampleFileEnding)
      const label =  name //idx === -1 ? name: name.substr(0,idx)

      return {
        id: f.$.id,
        name,
        label,
        url: p.value,
        accession: p.accession
      }
    }).filter(x => x))
    .flattenDeep()
    .value()

const species = doc => _
    .chain(doc.ProteomeXchangeDataset.SpeciesList)
    .map(speciesList => speciesList.Species.map(s => {

          const scientificName = cvParam(s, "taxonomy: scientific name")[0].value
          const ncbiTaxonomyId = cvParam(s, "taxonomy: NCBI TaxID")[0].value

          return {
            ncbiTaxonomyId,
            scientificName
          }
        })
    )
    .flattenDeep()
    .first()
    .value()

export const extractInfosFromPDXForMS = doc => {

  const citationAndPMIDDict = citationAndPMID(doc)

  return {
    //submitterEmail: submitterEmail(doc),
    species: species(doc),
    instruments: instruments(doc),
    modifications: modifications(doc),
    sampleList: sampleList(doc),
    ...citationAndPMIDDict
  }
}
