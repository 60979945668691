import React, {useState, useContext, useReducer, useEffect} from "react";

import {centeringCol} from "./utils";
import {parseRiboSeqStudy} from "../parsers/softRiboSeqParser";
import {enzymes, fragmentations, mods as msMods, userMods} from "../massSpec/peptideShakerArgs";
import _ from "lodash";
import {post, studyExists, useOpenProtGlobalState} from "../rest";
import {extractInfosFromPDXForMS} from "../parsers/pxdXMLParser";
import {MainMenu} from "../uiComponents/MainMenu";


// GSE129191

const treatmentTime = "n/a,pre-lysis,post-lysis".split(",").map(s => ({
    id: s,
    name: s
}))

const drugChoices = `n/a,chloramphenicol,cycloheximide,harringtonine,lactimidomycin,puromycin
`.split(",").map(s => ({
    id: s,
    name: s
}))



const postRiboSeqStudy = data =>
    post("/api/2/studies/ribo_seq", data)

const initialState = () => ({
    initialized: false,
    mainForm: {},
    groupIdGenerator: 0,
    sampleHoldersToSelect:[],
    groups:[]
})

const riboSeqStudyFormReducer = (state, action) => {

    const firstT = () => {
        switch (action.type) {
            case "reset":
                return initialState()
            case "setSavedId": {
                return {
                    ...state,
                    id: action.id,
                    saveSuccessful: true
                }
            }
            case "initFromSaved": {
                return {
                    ...initialState(),
                    initialized: true,
                    ...action.dehydratedState,
                    sampleHoldersToSelect: action.extractedInfos["!Series_sample_id"].map(s => ({
                        isSelected: false,
                        sample: {
                            id: s,
                            name: s
                        }
                    }))
                }
            }
            case "initFromParsedStudy":
                return {
                    ...initialState(),
                    initialized: true,
                    studyId: action.studyId,
                    mainForm: {
                        contactEmail: "",
                        pmid: action.extractedInfos["!Series_pubmed_id"][0],
                        title: action.extractedInfos["!Series_title"][0]
                    },
                    sampleHoldersToSelect: action.extractedInfos["!Series_sample_id"].map(s => ({
                        isSelected: false,
                        isExcluded: false,
                        sample: {
                            id: s,
                            name: s
                        }
                    })),
                    msInstrumentChoices: action.extractedInfos.instruments
                }
            case "updateMainFormField": {

                return {
                    ...state,
                    mainForm: {
                        ...state.mainForm,
                        [action.fieldKey]: action.fieldValue
                    }
                }
            }

            case "excludeSelectedSamples": {

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.map(s => ({
                        ...s,
                        isExcluded: s.isSelected || s.isExcluded,
                        isSelected: false
                    }))
                }
            }

            case "unExclude": {

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.map(s => ({
                        ...s,
                        isExcluded: false
                    }))
                }
            }

            case "assignSelectedStatus": {

                const sampleHoldersToSelect = state.sampleHoldersToSelect.map(h => !action.pred(h) ? h : ({
                    ...h,
                    isSelected: action.isSelected,
                }))

                return {
                    ...state,
                    sampleHoldersToSelect,
                    hasSampleSelected: sampleHoldersToSelect.find(h => h.isSelected)
                }
            }
            case "groupSelectedSamples": {

                const samples = state.sampleHoldersToSelect.filter(h => h.isSelected).map(h => h.sample)

                return {
                    ...state,
                    sampleHoldersToSelect: state.sampleHoldersToSelect.filter(h => !h.isSelected),
                    groupIdGenerator: state.groupIdGenerator + 1,
                    groups: [
                        {
                            id: state.groupIdGenerator,
                            species: null,
                            treatmentTime: null,
                            drug: null,
                            samples,
                        },
                        ...state.groups
                    ],
                    hasSampleSelected: false
                }
            }
            case "removeSampleFromGroup": {
                return {
                    ...state,
                    sampleHoldersToSelect: [
                        {
                            isSelected: false,
                            sample: action.sample
                        },
                        ...state.sampleHoldersToSelect
                    ],
                    groups: state.groups.map(g => {

                        if (g.id === action.group.id) {
                            if (g.samples.length === 1) {
                                return null
                            }
                            return {
                                ...g,
                                samples: g.samples.filter(s => s.id !== action.sample.id)
                            }
                        }
                        return g
                    }).filter(x => x)
                }
            }
            case "updateGroup": {

                const updateGroup = group => {
                    switch (action.key) {
                        case "sampleType":
                        case "treatmentTime":
                        case "drug":
                        case "species":
                            return {
                                ...group,
                                [action.key]: action.value
                            }
                        case "identificationProtocolSelection": {
                            return {
                                ...group,
                                identificationProtocolSelection: {
                                    userSelection: action.value
                                }
                            }
                        }
                        case "addSamples": {
                            return {
                                ...group,
                                samples: [
                                    ...state.sampleHoldersToSelect.filter(h => h.isSelected).map(h => h.sample),
                                    ...group.samples
                                ]
                            }
                        }
                        case "updateSample": {
                            return {
                                ...group,
                                samples: group.samples.map(s => {
                                    if (action.args.sample.id !== s.id) {
                                        return s
                                    }
                                    return {
                                        ...s,
                                        [action.args.propKey]: action.value
                                    }
                                })
                            }
                        }
                        default:
                            throw Error(`unknown group prop : ${action.key}`)
                    }
                }

                return {
                    ...state,
                    sampleHoldersToSelect:
                        action.key === "addSamples" ?
                            state.sampleHoldersToSelect.filter(h => !h.isSelected) :
                            state.sampleHoldersToSelect,
                    groups: state.groups.map(g => g.id === action.group.id ? updateGroup(g) : g)
                }
            }

            default:
                throw Error(`unknown action type: ${action.type}`)
        }
    }

    const t1 = firstT()
    const groups = t1.groups.map(g => ({
        ...g,
        errors: validateGroup(g)
    }))

    const hasGroupInError = !! groups.find(g => g.errors.hasErrors)

    t1.missingContactEmail = ! t1.mainForm.contactEmail

    const numberOfExcludedSamples =
        t1.sampleHoldersToSelect.filter(h => h.isExcluded).length

    const samplesAllGroupedOrExcluded = !( t1.sampleHoldersToSelect.length > numberOfExcludedSamples)

    return {
        ...t1,
        groups,
        hasGroupInError,
        numberOfExcludedSamples,
        samplesAllGroupedOrExcluded,
        canSubmitStudy: (! hasGroupInError) && samplesAllGroupedOrExcluded && (!t1.missingContactEmail)
    }
}

const validateGroup = group => {

    const errorList = _
        .chain(group)
        .toPairs()
        .map(([groupPropKey, v]) => {

            const msg = ()=> {
                switch (groupPropKey) {
                    case "id":
                    case "samples":
                    case "errors":
                    case "sampleType":
                        return null
                    case "species" : {
                        if (!v) {
                            return `select ${groupPropKey}`
                        }
                        return null
                    }
                    case "drug": {
                        if (!v) {
                            return `Select Drug`
                        }
                    }
                    case "treatmentTime" : {
                        if (!v) {
                            return `Select Treatment Time`
                        }
                        return null
                    }
                }
            }

            return [groupPropKey, msg()]
        })
        .value()

    const errorDict = _.chain(errorList).fromPairs().value()

    const hasErrors = errorList.filter(([k,v])=> v !== null)

    return {
        hasErrors: hasErrors.length > 0,
        ...errorDict
    }
}


const tdS = () => <td style={{"borderLeftStyle": "none", "borderRightStyle": "none"}}></td>

const SAMPLE_TABLE_COL_COUNT = 1
const spaceRow = () => <tr style={{backgroundColor: "white"}}>
  {tdS()}{tdS()}{tdS()}
</tr>



const SampleGroupEditor = ({group, dispatchUpdateGroup, riboSeqStudyFormState, allSpecies}) => {

    if(!group) {
        throw Error("group can't be falsy")
    }

    const groupErrors = group.errors

    const dropdown = (propKey, label, choices, choiceDisplay, choiceId) => {


        const getValue = () => {
            const v = group[propKey]
            return v ? choiceId(v): ""
        }

        return <div className="field">
            <label className="label is-small">{label}</label>
            <div className="control">
                <div className={`select is-small ${groupErrors[propKey] ? "is-danger": ""}`}>
                    <select value={getValue()} onChange={e => {
                        const selectedChoice = choices.find(c => choiceId(c) == e.target.value)
                        dispatchUpdateGroup(group, propKey, selectedChoice)
                    }}
                    >
                        <option key={"none"} value={""}></option>
                        {choices.map(c => <option key={propKey+c.id} value={c.id}>{choiceDisplay(c)}</option>)}
                    </select>
                </div>
            </div>
        </div>
    }

    const textGroupProperty = (propKey, label, placeholder) =>
        <div className="field">
            <label className="label is-small">{label}</label>
            <div className="control">
                <input
                    className="input is-small"
                    type="text"
                    placeholder={placeholder}
                    value={group[propKey] || ""}
                    onChange={e => {
                        dispatchUpdateGroup(group, propKey, e.target.value)
                    }}
                />
            </div>
        </div>

    const textAreaProperty = (propKey, label, rows) =>
        <div className="field">
            <label className="label is-small">{label}</label>
            <div className="control">
                <textarea
                    className="textarea is-small"
                    value={group[propKey] || ""}
                    rows={rows}
                    onChange={e => {
                        dispatchUpdateGroup(group, propKey, e.target.value)
                    }}
                />
            </div>
        </div>

    const groupEditor = () => <>
        <div className="columns">
            <div className="column">
                {dropdown("species", "Species", allSpecies, o => o.scientific_name, o => o.id)}
                {/*protocolIdentificationField()*/}
            </div>
            <div className="column">
                {dropdown("treatmentTime", "Treatment Time", treatmentTime, o => o.name, o => o.id)}
            </div>
            <div className="column">
                {dropdown("drug", "Drug Used", drugChoices, o => o.name, o => o.id)}
            </div>
            <div className="column">
                {textGroupProperty("sampleType","Sample Type", "Toe skin...")}
            </div>
        </div>
    </>
    return groupEditor()
}

const StudyMainFields = ({riboSeqStudyFormState, onUpdate}) => {

    const mainForm = riboSeqStudyFormState.mainForm

    return <>
        <div className="columns">
            <div className="column">
                <div className="field">
                    <label className="label">PMID</label>
                    <div className="control">
                        <input className="input" type="text" disabled={true} readOnly={true} value={mainForm.pmid}/>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="field">
                    <label className="label">Contact Email</label>
                    <div className="control has-icons-left has-icons-right">
                        <input
                            className={`input ${riboSeqStudyFormState.missingContactEmail ? "is-danger": ""}`}
                            type="email"
                            placeholder="profA@example.org"
                            onChange={e => onUpdate('contactEmail', e.target.value)}
                            value={mainForm.contactEmail}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      <div className="columns">
            <div className="column">
                <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                        <textarea
                            className="textarea"
                            value={mainForm.title}
                            disabled={true}
                            rows={2}
                            readOnly={true}/>
                    </div>
                </div>
            </div>
        </div>
     </>
}

const SubmitRiboSeqStudy = ({dehydratedState, onCancel}) => {


    const {openProtGlobalState} = useOpenProtGlobalState()

    const [studyId, setStudyId] = useState("")

    const [riboSeqStudyFormState, dispatch] = useReducer(riboSeqStudyFormReducer, initialState())

    const [studyFetchInProgress, setStudyFetchInProgress] = useState(false)

    const [selectedGroupId, setSelectedGroupId] = useState(null)

    const [fetchError, setFetchError] = useState(null)

    useEffect(() => {

        if(!dehydratedState) {
            return
        }

        setStudyFetchInProgress(true)

        fetchTheStudy(dehydratedState.studyId)
        .then(doc => {

            setStudyFetchInProgress(false)
            const extractedInfos = parseRiboSeqStudy(doc)
            dispatch({type: "initFromSaved", dehydratedState, extractedInfos})
        })
        .catch(err => {
            setStudyFetchInProgress(false)
            console.log(err)
            //setFetchError(err)
        })
    },[])

    const setAlreadySubmitted = () =>
        setFetchError({
            title:"Study already submitted",
            message: `Study ${studyId} was previously submitted to OpenProt`,
            messageClass: "is-warning",
            onClose: cancelStudy
        })

    if(openProtGlobalState === null) {
        return <div>Loading...</div>
    }

    const allSpecies = openProtGlobalState.lastRelease.allSpecies

    const selectedGroup =
        selectedGroupId === null ? null :
            riboSeqStudyFormState.groups.find(g => g.id === selectedGroupId)

    const fetchTheStudy = studyId =>
            fetch(`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${studyId}&targ=self&form=text`)
            .then((response) => response.text())

    const submitStudy = async () => {

        const toSubmit = {
            mainForm: riboSeqStudyFormState.mainForm,
            groups: riboSeqStudyFormState.groups,
            studyId: riboSeqStudyFormState.studyId
        }

        const res = await postRiboSeqStudy(toSubmit)

        dispatch({type: 'setSavedId', id: res.id})
    }

    const cancelStudy = () => {
        dispatch({type: "reset"})
        setFetchError(null)
        if(onCancel) {
            onCancel()
        }
    }

    const fetchStudy = () => {

        setStudyFetchInProgress(true)
        studyExists("ribo_seq", studyId).then(res => {

            if (res.exists) {
                setAlreadySubmitted()
                setStudyFetchInProgress(false)
                return
            }

            fetchTheStudy(studyId)
            .then((res) => {
                setStudyFetchInProgress(false)
                if(res.indexOf("<!DOCTYPE HTML PUBLIC") === 0) {
                    setFetchError({
                        title:"Study not found",
                        message: `Study ${studyId} not found`,
                        messageClass: "is-warning",
                        onClose: cancelStudy
                    })
                    return
                }
                const extractedInfos = parseRiboSeqStudy(res)
                dispatch({type: "initFromParsedStudy", extractedInfos, studyId})
            })
        })
    }

    const dispatchUpdateGroup = (group, key, value, args) => dispatch({
        type:"updateGroup",
        group, key, value, args
    })


    const samplePicker = () => <div>
        {centeringCol("is-half", <>

            <button
                className="button"
                disabled={! riboSeqStudyFormState.hasSampleSelected}
                onClick={() => dispatch({type: "groupSelectedSamples"})}
            >
                Group Selected
            </button>
            <button
                className="button"
                disabled={! riboSeqStudyFormState.hasSampleSelected}
                onClick={() => dispatch({type: "assignSelectedStatus", pred: s => true, isSelected: false})}
            >
                Clear Selection
            </button>
            <button
                className="button"
                onClick={() => dispatch({type: "assignSelectedStatus", pred: s => ! s.isExcluded, isSelected: true})}
            >
                Select All
            </button>
            <button
                className="button"
                disabled={! riboSeqStudyFormState.hasSampleSelected}
                onClick={() => dispatch({type: "excludeSelectedSamples"})}
            >
                Exclude Selection
            </button>
            <button
                className="button"
                disabled={! selectedGroup}
                onClick={() => dispatch({type: "updateGroup", group: selectedGroup, key: "addSamples"})}
            >
                Add to Selected Group
            </button>
            {riboSeqStudyFormState.numberOfExcludedSamples > 0 &&
            <button
                className="button"
                onClick={() => dispatch({type: "unExclude"})}
             >
                <span>{riboSeqStudyFormState.numberOfExcludedSamples} samples(s) are excluded, click to unexclude</span>
            </button>}
        </>)}
        <div className="buttons">
            {riboSeqStudyFormState.sampleHoldersToSelect.filter(h => !h.isExcluded).map(h =>
                <button
                    key={h.sample.name}
                    className={`button is-small ${h.isSelected ? "is-link is-light": ""}`}
                    onClick={() => dispatch({
                        type: "assignSelectedStatus",
                        pred: s => s.sample.id === h.sample.id, isSelected: ! h.isSelected
                    })}
                >{h.sample.name}
                </button>
            )}
        </div>
    </div>


    const tableStyle = {width: "100%"}

    const sampleGroupAttributesTop = group => {
        const errors = group.errors

        const msg = () => errors.hasErrors ?
            <>
            <span className="icon has-text-warning">
                <i className="fas fa-info-circle"></i>
            </span>
                Sample group not ready to submit
            </>:
            <>
                <span className="icon has-text-success">
                    <i className="fas fa-check-square"></i>
                </span>
                Sample group ready
            </>

      return msg()
    }

    const SamplePill = ({group, sample}) => {

        const zaz = ()=> <><span className="tag is-medium is-info is-light">Fraction </span>
            <span className="tag is-medium is-info is-light">Replicate </span>
            <a
              className="tag is-medium"
              onClick={e => {
                  e.stopPropagation()
                  openModal(() => <PopupEditSample group={group} sample={sample} dispatch={dispatch}/>)
              }}
            >Edit</a></>

        return <div className="tags has-addons ">
            <span className="tag is-rounded  is-medium">{sample.name}</span>
            <a
              className="tag is-delete is-medium  is-rounded"
              onClick={e => {
                  e.stopPropagation()
                  dispatch({type: "removeSampleFromGroup", group, sample})
              }}
            ></a>
        </div>
    }


    const sampleTable = () =>
      <table className="table is-bordered" style={tableStyle}>
          <thead>
            <tr>
                <th>Samples</th>
            </tr>
          </thead>
          {
              riboSeqStudyFormState.groups.map(group => {

                  const isGroupSelected = group.id == selectedGroupId

                  const bodyStyle = ! isGroupSelected ? {} : {backgroundColor: "rgba(243, 243, 243, 0.66)"}

                  return <tbody
                      key={group.id}
                      style={bodyStyle}
                      onClick={() => setSelectedGroupId(group.id)}
                  >
                  <tr>
                      <td colSpan={SAMPLE_TABLE_COL_COUNT}>
                          {sampleGroupAttributesTop(group)}
                      </td>
                  </tr>
                  {
                      group.samples.map((sample, idx) =>
                          <tr key={"sample_row_"+sample.id}>
                              <td>
                                  <SamplePill sample={sample} group={group}/>
                              </td>
                          </tr>
                      )
                  }
                  <tr>
                      <td colSpan={SAMPLE_TABLE_COL_COUNT}>
                          <SampleGroupEditor
                              dispatchUpdateGroup={dispatchUpdateGroup}
                              group={group}
                              allSpecies={allSpecies}
                              pxdFormState={riboSeqStudyFormState}
                          />
                      </td>
                  </tr>
                  {spaceRow()}
                  </tbody>
              })
          }
      </table>

    const fetchPanel = () => riboSeqStudyFormState.initialized ? null :
        <>
            <MainMenu/>
            {centeringCol('is-one-third', <>
          <div className="field has-addons">
              <div className="control">
                  <input className="input" type="text" value={studyId}
                    onChange={e => setStudyId(e.target.value)}  placeholder="ex: GSE129194..."/>
              </div>
              <div className="control">
                  <button className="button is-info" onClick={() => fetchStudy()} disabled={! studyId}>
                      Load RiboSeq Study
                  </button>
              </div>
          </div>
          {studyFetchInProgress &&
            <progress className="progress is-primary is-large" max="100">15%</progress>
          }
        </>)}
        </>

    const submitMessage = () => {

        const m = () => {
            if(riboSeqStudyFormState.missingContactEmail) {
                return ["Please enter a contact email", false]
            }
            if (! riboSeqStudyFormState.samplesAllGroupedOrExcluded) {
                return ["All samples must be grouped before submit", false]
            }
            if (riboSeqStudyFormState.hasGroupInError) {
                return ["Some sample groups have missing information, can't submit yet ", false]
            }

            return ["ready to submit", true]
        }

        const [txt, isReady] = m()

        const messageClass =  ! isReady ? "is-warning" : "is-success"

        return <article className={`message ${messageClass}`}>
            <div className="message-body">
                {txt}
            </div>
        </article>
    }

    if(riboSeqStudyFormState.saveSuccessful) {
        return centeringCol("is-half", <>
            <article className="message">
                <div className="message-header">
                    <p>Study Submitted</p>
                    <button className="delete" aria-label="delete"></button>
                </div>
                <div className="message-body">
                     Submission completed! Thank you for your submission, you will receive a confirmation email from us (please check your spam folder if you don't see it in your inbox)

                    <button
                        className="button"
                        onClick={cancelStudy}
                    >Ok</button>
                </div>
            </article>
        </>)
    }

    if(fetchError) {
        return centeringCol("is-half", <>
            <article className={`message ${fetchError.messageClass}`}>
                <div className="message-header">
                    <p>{fetchError.title}</p>
                    <button className="delete" onClick={() => fetchError.onClose()} aria-label="delete"></button>
                </div>
                <div className="message-body">
                    {fetchError.message}

                    <button
                        className="button"
                        onClick={() => fetchError.onClose()}
                    >Ok</button>
                </div>
            </article>
        </>)
    }

    if(! riboSeqStudyFormState.initialized) {
        return fetchPanel()
    }

    const studyBox = () => ! riboSeqStudyFormState.initialized ? null :
        <>
            <MainMenu/>
        <div className={"box"}>
            <div className="title is-3">Ribo Seq Study {studyId}</div>
            <StudyMainFields
                riboSeqStudyFormState={riboSeqStudyFormState}
                onUpdate={(fieldKey, fieldValue) => dispatch({type: "updateMainFormField", fieldKey, fieldValue})}
            />
            {submitMessage()}
            {samplePicker()}
            {sampleTable()}
            {submitMessage()}
                <div className="columns is-mobile is-centered">
                    <div className="column is-one-third">
                        <div className="columns">
                            <div className="column">
                                {(! riboSeqStudyFormState.id) &&
                                    <button className="button is-primary is-large" onClick={submitStudy} disabled={! riboSeqStudyFormState.canSubmitStudy}>
                                        Submit Study
                                    </button>
                                }
                                {riboSeqStudyFormState.id &&
                                    <button className="button is-primary is-large" onClick={() => {}} disabled={! riboSeqStudyFormState.canSubmitStudy}>
                                        Save Study
                                    </button>
                                }
                            </div>
                            <div className="column">
                              <button className="button is-large" onClick={cancelStudy}>
                                  Cancel
                              </button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </>

    return studyBox()
}


export default SubmitRiboSeqStudy