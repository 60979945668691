
import React from "react";


export const centeringCol = (colSizeClass, innerContent) =>
    <div className="columns is-mobile is-centered">
      <div className={`column ${colSizeClass}`}>
          {innerContent}
      </div>
    </div>


export const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
}


